// add placeholder text if hide labels class is added to form
const hideLabelsContainer = document.querySelector('.visually-hide-labels');

if (hideLabelsContainer) {
  const formGroups = hideLabelsContainer.querySelectorAll('.form-group');

  formGroups.forEach(function (formGroup) {
    const labelTitle = formGroup.firstElementChild.getAttribute('title');
    const groupInput = formGroup.querySelector('.form-control');

    if (labelTitle !== null) {
      groupInput.setAttribute('placeholder', labelTitle);
    }
  });
}
