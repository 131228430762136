import $ from 'jquery';

$(function () {
  $('#phonePopover').popover({
    html: true,
    content: phonePopoverContentHTML,
    trigger: 'click',
  })
});

const phonePopoverContent = document.querySelector('#phonePopoverContent');
const phonePopoverContentHTML = phonePopoverContent ? phonePopoverContent.innerHTML : '';
