import $ from 'jquery';

const dismissedAlertsStorageKey = 'dismissed-alerts';
const dismissedAlerts = sessionStorage.getItem(dismissedAlertsStorageKey) === null
  ? []
  : sessionStorage.getItem(dismissedAlertsStorageKey).split(';');

// When an alert has been dismissed, then dismiss it for the rest of the session
$('.alert-banner .alert').each(function() {
  const alertName = $(this).data('alert-name');
  if (dismissedAlerts.indexOf(alertName) !== -1) {
    $(this).removeClass(['fade', 'show']); // Hide the banner immediately!
    $(this).alert('close');
  } else {
    $(this).on('closed.bs.alert', function() {
      dismissedAlerts.push(alertName);
      sessionStorage.setItem(dismissedAlertsStorageKey, dismissedAlerts.join(';'));
    });
  }
});

// Show the alert banner after its alerts have been processed
$('.alert-banner').addClass('show');
