import $ from 'jquery';
// Leave site notice modal on links using non-whitelisted external domains

// Gets the domain from the provided URL
function getDomain(url) {
  const prefix = /^https?:\/\//i;
  const domain = /^[^\/:]+/;

  url = url.replace(prefix, '');

  if (url.charAt(0) === '/') {
    url = window.location.hostname + url;
  }

  const match = url.match(domain);
  if (match) {
    return match[0];
  }

  return null;
}

// Shows the leave site notice, using the provided anchor
function showLeaveSiteNotice($anchor) {
  const url = $anchor.attr('href');
  const text = $anchor.text();
  const target = $anchor.attr('target');

  $('.modal').modal('hide'); // Hide all other modals before showing the leave site modal
  $('#leaveSiteModal').modal();

  $('#continueToSite').attr('href', url);
  $('#continueToSite').attr('target', target);
}

// Click event for page anchors
$('body').on('click', 'a[href^=http]', function (e) {
  e.preventDefault();
  const $anchor = $(this);
  const domain = getDomain($anchor.attr('href'));
  const linkHref = $anchor.attr('href');
  const LinkTarget = $anchor.attr('target');
  const isWhitelisted = whiteList.some(res => res.includes(domain));

  if (domain.indexOf(location.hostname) === -1) {
    if (!isWhitelisted) {
      showLeaveSiteNotice($anchor);
    } else {
      LinkTarget
        ? window.open(linkHref, LinkTarget)
        : window.location = linkHref;
    }
  }
});
