import { scrollToContainer } from './scroll-to-container.js';

const anchorLinkCta = document.querySelector('.anchor-link-cta');
const anchorLinkCtaLinks = anchorLinkCta ? anchorLinkCta.querySelectorAll('a') : '';

function checkNumAnchorLinks() {
  if (anchorLinkCta) {
    const numberOfAnchorLinks = document.querySelectorAll('.anchor-link-cta li').length;
    const anchorCTA = document.querySelector('.anchor-link-cta .cta');

    if (numberOfAnchorLinks === 0 && anchorCTA === null) {
      anchorLinkCta.classList.add('editmode-only');
    }
  }
}

function handleStickyAnchorLinksCTA() {
  const headerHeight = document.querySelector('header').clientHeight;
  let containerRect = '';

  if (anchorLinkCta) {
    containerRect = anchorLinkCta.getBoundingClientRect();

    if (containerRect.top < headerHeight) {
      anchorLinkCta.style.top = headerHeight + 'px';
    } else if (containerRect.top === headerHeight) {
      anchorLinkCta.classList.add('active');
    } else {
      anchorLinkCta.classList.remove('active');
      anchorLinkCta.removeAttribute('style');
    }
  }
}

function anchorCtaLinks(e) {
  e.preventDefault();
  const linkHref = e.currentTarget.getAttribute('href');
  const hrefIndexOfHash = linkHref.indexOf('#');

  if(hrefIndexOfHash === -1) {
    window.location = linkHref;
  } else if(hrefIndexOfHash > 0) {
    const hash = '#' + linkHref.split('#')[1];

    scrollToContainer(hash);
  }
}

window.addEventListener('load', function () {
  checkNumAnchorLinks();
  handleStickyAnchorLinksCTA();

  // if url hash, scroll to container
  window.location.hash ? scrollToContainer(window.location.hash) : '';

  window.addEventListener('scroll', handleStickyAnchorLinksCTA);

  if (anchorLinkCtaLinks) {
    anchorLinkCtaLinks.forEach(function (link) {
      link.addEventListener('click', anchorCtaLinks);
    });
  }
});
