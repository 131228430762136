const promoContainer = document.querySelector('.responding-promotion');
const referenceCodeField = document.querySelector('.reference-code-field');
const showHidePromoBtn = document.querySelector('#showHidePromoBtn');
const invalidPromoCode = document.querySelector('#invalidPromoCode');

function handlePromoVisibility(e) {
  if (promoContainer.classList.contains('active')) {
    promoContainer.classList.remove('active');
  } else {
    promoContainer.classList.add('active');
    referenceCodeField.focus();
  }
}

if (showHidePromoBtn) {
  showHidePromoBtn.addEventListener('click', handlePromoVisibility);
}

if (invalidPromoCode) {
  promoContainer.classList.add('active');
  referenceCodeField.focus();
}
