const body = document.querySelector('body');
const navbarToggler = document.querySelector('.navbar-toggler');

function handleNavbarToggler(e) {
  const toggler = e.currentTarget;
  const togglerExpanded = toggler.getAttribute('aria-expanded');

  console.log(togglerExpanded);
  togglerExpanded === 'true'
    ? body.classList.remove('overflow-hidden')
    : body.classList.add('overflow-hidden');
}

navbarToggler.addEventListener('click', handleNavbarToggler);
