import $ from 'jquery';

/* functions */
function triggerModal() {
  let triggerDelay = $('#globalPopupOverlayModal').data('timing');
  setTimeout(function () {
    $('#globalPopupOverlayModal').modal({
      backdrop: 'static'
    });
  }, triggerDelay);
}

function modalDialog() {
  var sessionVal = sessionStorage.getItem('globalPopupOverlaySession');

  if (sessionVal !== 'dismissed')
    triggerModal();
}

function setSessionStorage() {
  sessionStorage.setItem('globalPopupOverlaySession', 'dismissed');
}

function modalClose() {
  $('#globalPopupOverlayModal').modal('hide');
}

function load() {
  modalDialog();
}

/* events */
$('#globalPopupOverlayModal').on('hidden.bs.modal', function () {
  setSessionStorage();
});

$('.modal-footer a').on('click', function (e) {
  e.preventDefault();

  var targetBlank = $(this).attr('target'),
      hrefVal = $(this).attr('href');

  setSessionStorage();
  modalClose();

  if (targetBlank === '_blank') {
    window.open(hrefVal, '_blank');
  } else {
    window.location.href = hrefVal;
  }
})

load();
