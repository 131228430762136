import $ from 'jquery';

const calculator = {
  debug: true,
  debug_log: ['offline'],
  dq: false,
  $container: $('#help-me-choose'),
  $containerCopy: $('#help-me-choose').clone(),
  resultStr: $('.custom-calculator-results strong strong').html(),
  _resultStr: $('.custom-calculator-results strong strong').html(),
  _attrGroup:[],
  _attrCollection:{},
  mode: (arr)=>{
      if (Array.isArray(arr)) {
        return arr.sort((a,b) =>
              arr.filter(v => v===a).length
            - arr.filter(v => v===b).length
        ).pop();
      } else { // assume we're given a string
        return arr;
      }
  },
  validKey: (st)=>{
      return (st.replace(/\W/g,'').toLowerCase());
  },
  init: (retry)=>{
      if (calculator.debug) {
          calculator.debug_log.push('calculator.init()');
          calculator.debug_log.push('calculator online');
          console.log(calculator.debug_log);
      }
      // position form
      setTimeout(()=>{

        $('#product-selector-form').prependTo('.custom-calculator-controls');
      },900);

      //browsers remember selections
      //clear inputs to be sure
      let $inputsClear = calculator.$container.find('input');
      $inputsClear.prop('checked', false);

      let savedAnswers = localStorage.getItem('submittedProductSelector');
      if (savedAnswers) {
        console.log(savedAnswers);

        // $('#help-me-choose').replaceWith(savedAnswers);

        localStorage.removeItem('submittedProductSelector');

      }

      if (retry) {
        calculator['dq'] =  false,
        calculator['resultStr'] =  calculator._resultStr, //copy for later
        calculator['_attrGroup'] = [],
        calculator['_attrCollection'] = {},
        calculator['$container'] = $('#help-me-choose'),
        $('.custom-calculator-results strong strong').hide();
        $('.disqualify').hide();
        $('#help-me-choose input').prop('checked', false);
        calculator.bindings(retry);
      }else{

        //remove result
        $('.custom-calculator-results strong strong').hide();
        $('.disqualify').hide();
        //establish bindings
        calculator.bindings();
        //example string template
        //Based on your answers, we recommend our {attributes} with a {attributes-cont}
      }

  },
  bindings: (retry)=>{
      if (calculator.debug) {
          calculator.debug_log.push('calculator.bindings()');
          console.log(calculator.debug_log, 'bindings');
      }

      $(document).keypress(
        function(event){
          if (event.which == '13') {
            event.preventDefault();
          }
      });

      calculator.$container.find('.custom-calculator-form').find('input').on('click', (e)=>{
          if (calculator.debug) {
              calculator.debug_log.push($(e.target));
              console.log(calculator.debug_log, 'answer added');
          }

          calculator.answer_handler($(e.target));
      });

      calculator.$container.find('button.btn-secondary').on('click', (e)=>{
          e.preventDefault();
          if (calculator.debug) {
              console.log(calculator.debug_log, 'Submit Calc');
          }

          if (calculator.$container.find('button').hasClass('reset')) {
            $('.opt-cta').hide();
            calculator.$container.find('button').toggleClass('reset');
            calculator.reset();
            return false;
          }

          calculator.valid = true;
          let saveAnswers = [];// for performance, use the same loop

          $.each($('.custom-calculator-form-wrapper .form-group'),(index, value)=>{
              console.log(value, index, 'index');
              let answer = {};
              answer.question = $(value).find('legend').text();
              answer.answer = $(value).find('input:checked').next().text();

              saveAnswers.push(answer);

              if (!$(value).hasClass('answered')) {
                  calculator.valid = false;
                  alert('Please fill in your answers to receive our recommendation.');
                  return false;
              }
          });

          saveAnswers.push($('.custom-calculator-results').text());

          //if email not there:product-selector-form

          if($('#product-selector-form .email-input').val() === ''){
            calculator.valid = false;
            alert('Please fill in your answers to receive our recommendation.');
            return false;
          }

          if (calculator.dq && calculator.valid) {
              $('.custom-calculator-results small').show();
          }else if(calculator.valid){



            //calculator.mode(calculator._attrGroup);
            // if CTA has value
            if ($('.opt-cta').attr('href') != '') {
              $('.opt-cta').show();
            }
            //hide submit button
            calculator.$container.find('button.btn-secondary').hide();
            //remove any success or error indicators
            $('#product-selector-form .InfoLabel').remove();
            $('#product-selector-form .ErrorLabel').remove();

            calculator._attrGroup.forEach(element => {
              let replace = calculator.validKey(element);
              let key     = calculator.validKey(element);
              if (calculator.debug) {
                console.log(calculator[key], replace, 'rplstr', calculator);
              }
              calculator[key] = calculator.mode(calculator[key]);
              calculator.resultStr = calculator.resultStr.replace('{'+replace+'}', calculator[key]);
            });

            // console.log('mode test', calculator.mode(calculator._attrGroup))
            $('.custom-calculator-results strong strong').html(calculator.resultStr);


            saveAnswers.push(calculator.resultStr);
            $('#product-selector-form .textarea-control').text(JSON.stringify(saveAnswers));

            // calculator.$container.find('button').on('click', ()=>{
              //   $('.opt-cta').hide();
              //   calculator.reset();
              // });
              // localStorage.setItem('submittedProductSelector', $('#help-me-choose')[0].outerHTML);
              $('#product-selector-form .FormButton').trigger('click');

              let formResponse = setInterval(function(){
                if ($('#product-selector-form .InfoLabel').length) {
                  $('#product-selector-form .InfoLabel').hide();
                  $('.custom-calculator-results strong strong').show();
                  //change the "Calculate" button to a "Restart"
                  let strRP = calculator.$container.find('button').text();
                  strRP = strRP.replace('Calculate', 'Reset');
                  calculator.$container.find('button').text(strRP);
                  calculator.$container.find('button').toggleClass('reset');
                  clearInterval(formResponse);
                  console.log('InfoLabel found')
                }
                if($('#product-selector-form .ErrorLabel').length){
                  clearInterval(formResponse);
                  //show submit button
                  calculator.$container.find('button.btn-secondary').show();
                  console.log('Err found')
                }
              }, 100);

          }
      });
  },
  answer_handler: ($answer)=>{
      let attr_group          = $answer.data('attribute-group');
      let attr_value          = $answer.data('attribute-value');
      let attr_weight         = $answer.data('attribute-weight');
      let attr_product        = $answer.data('recommended-product');
      let attr_product_weight = $answer.data('recommended-product-weight');
      let attr_dq             = $answer.data('disqualify');
      let key                 = calculator.validKey(attr_group);

      //validation
      $answer.parent().parent().parent().addClass('answered');
console.log($answer, 'answer check retry');
      if (attr_dq == 'Yes') {
          calculator.dq = true;
      }

      if (!calculator._attrGroup.includes(attr_group) && attr_group != '') {
          calculator._attrGroup.push(attr_group);
      }

      //is the key old and not an arr?
      if (!calculator[key] && attr_group != '' && !Array.isArray(calculator[key])) {
        //not an array which means we got an answer
        calculator[key] = [];// make index arrayer again
      }

      if (!calculator[key] && attr_group != '') {

          calculator[key] = [];

          //physically factor weight
          for (let index = 0; index < attr_weight; index++) {
              calculator[key].push(attr_value);
          }
      }else if(attr_group != ''){
          //physically factor weight
          for (let index = 0; index < attr_weight; index++) {
              calculator[key].push(attr_value);
          }
      }


      if (calculator.debug) {
          calculator.debug_log.push(calculator._attrGroup);
          console.log(calculator.debug_log, 'built attributes collection');
      }


  },
  submit_handler: ()=>{
      if (calculator.debug) {
          calculator.debug_log.push(calculator.resultStr);
          console.log(calculator.debug_log, 'Submit Calc');
      }
  },
  reset: ()=>{
      if (calculator.debug) {
          calculator.debug_log.push('calculator.reset()');
          console.log(calculator.debug_log, 'restart/reset quiz');
          console.log(calculator, 'what happens to this?');
      }

      //wholesale replace with copy made earlier;
      //calculator.$container.replaceWith(calculator.$containerCopy);
      $('.custom-calculator-results strong strong').hide();
      $('.disqualify').hide();
      $('#help-me-choose input').prop('checked', false);
      calculator._attrGroup = [];
      calculator._attrCollection = [];
      calculator.resultStr = calculator._resultStr;
      calculator.dq = false;
      let strRP = calculator.$container.find('button').text();
      strRP = strRP.replace('Reset', 'Calculate');
      calculator.$container.find('button').text(strRP);
      //odd behavior


window.location.href = window.location.href + '#help-me-choose';//killing too much time


  }

}

$(document).ready(()=>{

  if ($('#help-me-choose').length) {
    calculator.init();
  }
});

const cdLadder = {
  debug: true,
  debug_log: ['offline'],
  _APY:[],
  _Months:[],
  runningTotal:0,
  runningTotal2:0, // calc 2
  runningTotal3:0,
  runningTotal4:0, // calc 2
  init: ()=>{
    if (cdLadder.debug) {
        cdLadder.debug_log.push('cdLadder.init()');
        cdLadder.debug_log.push('cdLadder online');
        // console.log(cdLadder.debug_log);
    }


    //JSON table dependency on the page as a local
    try {
      JSON.stringify(customCDTable);// an error here would mean it's invalid or not available
      customCDTable = Object.values(customCDTable);
    } catch (error) {
      console.log('Missing JS Dependency["customCDTable"]');
      $('.cd-ladder-calc').empty();
      $('.cd-ladder-calc').html('<h1>Error</h1><br><p>Please try refreshing the page.</p>');
    }

    //establish bindings
    cdLadder.bindings();
    //example string template

  },
  queryHandler: (cdType, initRenew, _months, rangeMin, rangeMax, customCDTable, q)=>{
    if (cdLadder.debug) {
        cdLadder.debug_log.push('cdLadder.queryHandler()');
        // console.log('queryHandler');
    }

    //clear each time
    cdLadder._APY = [];
    cdLadder._Months = [];

    //if initial query
    if (initRenew == 'initial') {
      // Look up the 4 unique APY and Months values for

      // CD Type = CD Ladder,
      // Initial/Renewal = Initial,
      // Months = [6, 12, 18, 24],
      // where input value Deposit Amount is in the Min 5000/250000 Maximum Deposit range
      for (let i = 0; i < customCDTable.length; i++) {
        let element = customCDTable[i];
        let minimum = (element['Minimum Deposit (Inclusive)'] == '') ? rangeMin : element['Minimum Deposit (Inclusive)'];
        let maximum = (element['Maximum Deposit (Exclusive)'] == '') ? rangeMax : element['Maximum Deposit (Exclusive)'];
        if (
          element['CD Type'] == cdType &&
          element['Initial Renewal'] == initRenew &&
          _months.includes(element['Months']) && //needs to be within
          minimum >= rangeMin &&//needs to be within
          maximum <= rangeMax//needs to be within
          ) {

            //check for dupes
            if (q == '_APY') {
              if (!cdLadder._APY.includes(element['APY'])) {
                cdLadder._APY.push(element['APY']);
              }
            }
            if (q == '_Months') {
              if (!cdLadder._Months.includes(element['Months'])) {
                cdLadder._Months.push(element['Months']);
              }
            }
        }
      }
    }

    if (q == '_APY') {
      return cdLadder._APY;
    }
    if (q == '_Months') {
      return cdLadder._Months;
    }


  },
  ladder:(CDLadderCalculation, customCDTable)=>{
    if (cdLadder.debug) {
      //  console.log('laddering', CDLadderCalculation);
    }
    var result = Object.keys(CDLadderCalculation).map(function(key) {
      return [CDLadderCalculation[key]];
    });
    // console.log(result, 'obj->arr');

    let years = parseInt($('.input-group select').val());
    let depositAmount = parseInt($('#depositAmount').val());
    let PrincipleCalculated = (depositAmount / 4);
    let CDLadderCalculation2 = {};

    for (let index = 0; index < result.length; index++) {
      const element = result[index][0];

      //for each ladder, perform the initial calc 2:
      // For each ladder,
      // while its Duration + 24 is less than or equal to input value Ladder Years * 12,
      // perform a renewal iteration calculation:
      let durationCondition =  (parseInt(element.Duration) + 24 <= years * 12);

      if (durationCondition) {
        // Look up the 1 unique APY and Month values for
        // CD Type = CD Ladder,
        // Initial/Renewal = Renewal,
        // Months = 24,
        let apy = [];//cdLadder.queryHandler('cd-ladder', 'renewal', ["24"], 5000, 250000, customCDTable, '_APY');
        // Look up the 1 unique APY and Months values for
        for (let x = 0; x < customCDTable.length; x++) {
          const row = customCDTable[x];
          //MIN AND MAX are from previous calculated totals
          let _months = ['24'];
          // where Previous Calculation's Total is in the Minimum/Maximum Deposit range:
          let prevCalcTotal = parseFloat(cdLadder.runningTotal);
          // console.log('prev total', cdLadder.runningTotal);
          if (
            row['CD Type'] == 'cd-ladder' &&
            row['Initial Renewal'] == 'renewal' &&
            _months.includes(row['Months']) && //needs to be within
            prevCalcTotal >= 5000 &&//needs to be within
            prevCalcTotal <= 250000//needs to be within
            ) {
              //check for dupes
              if (!apy.includes(row['APY'])) {
              apy.push(row['APY']);
            }

            // console.log('apy check', apy, prevCalcTotal); // this pulls back the expected one apy
          }
        }
        // //add calc obj
        let monthCalculated = 24;
        let IntersetCalculated = (CDLadderCalculation[index].Total * apy[0] * (monthCalculated/12));
        // console.log('the new interest calculated', CDLadderCalculation[index].Total, apy[0] , (monthCalculated/12));

        //log a running total as last property for access later
        cdLadder.runningTotal2 = cdLadder.runningTotal2 + (IntersetCalculated + PrincipleCalculated);
        CDLadderCalculation2[index] = {
          'APY': apy[0], //there should only be one returned per spec! "Look up the 1 unique APY and Month values"
          'Principle': cdLadder.runningTotal, //[Previous Calculation's Total]
          'Interest': IntersetCalculated, //Principle * APY * (Months / 12)
          'Total': (IntersetCalculated + cdLadder.runningTotal),//Principle + Interest
          'Duration': 24,//sumOfMonths(monthCalculated), // [Previous Calculation's Months] + Months
          'GrandTotal' : cdLadder.runningTotal2 //for later use; calculate the some of totals
        }
      }
    }

    return CDLadderCalculation2;
  },
  bindings:()=>{
    if (cdLadder.debug) {
        cdLadder.debug_log.push('cdLadder.bindings()');
        console.log(cdLadder.debug_log, 'bindings');
    }

    $('#Submit').on('click', ()=>{
      //simple validation
      let depositAmount = parseInt($('#depositAmount').val());
      let years = parseInt($('.input-group select').val());

      //calculations based on spec
      //https://docs.google.com/document/d/1hymRu7PTdcfjfFeBVL-BcqOJMJczQ58WZkRavN6XXYI/edit
      let CDLadderCalculation  = {};
      let CDLadderCalculation2;

      let NOLadders = 4 //believe this to remain fixed
      let _months = ['6', '12', '18', '24']; //values to check

      //first calc query
      let _APY = cdLadder.queryHandler('cd-ladder', 'initial', _months, 5000, 250000, customCDTable, '_APY');
      let _Months = cdLadder.queryHandler('cd-ladder', 'initial', _months, 5000, 250000, customCDTable, '_Months');
      let months = _Months;

      // console.log(_APY, 'return 4 apy', 'passes assuming first 4');
      //for each ladder, perform the initial calc 1:
      for (let index = 0; index < NOLadders; index++) {

        if (cdLadder.debug) {
            cdLadder.debug_log.push('cdLadder APY + Month');
            cdLadder.debug_log.push(_APY);
            cdLadder.debug_log.push(months);
            // console.log(cdLadder.debug_log, 'CALC 1:1');
        }

        let PrincipleCalculated = (depositAmount / 4);
        let monthCalculated = parseFloat(months[index]);
        let apyCalculated = parseFloat(_APY[index]);
        let IntersetCalculated = (PrincipleCalculated * apyCalculated * (monthCalculated/12));

        cdLadder.runningTotal = cdLadder.runningTotal + (IntersetCalculated + PrincipleCalculated);
        CDLadderCalculation[index] = {
          'APY': _APY[index],
          'Principle': PrincipleCalculated,
          'Interest': IntersetCalculated,
          'Total': (IntersetCalculated + PrincipleCalculated),
          'Duration': months[index],
          'GrandTotal' : cdLadder.runningTotal +  (IntersetCalculated + PrincipleCalculated)//for later use; calculate the some of totals
        };
        //log a running total as last property for access later



        //[Previous Calculation's Months] + Months
        //attach to obj for ref
        CDLadderCalculation['months'] = months;
      }

      CDLadderCalculation2 = cdLadder.ladder(CDLadderCalculation, customCDTable);


      //at this point we have our calc 1 + 2 complete
      if (cdLadder.debug) {
        // cdLadder.debug_log.push('CALC 1:* Complete!');
        // cdLadder.debug_log.push(CDLadderCalculation);
        console.log(CDLadderCalculation, 'CALC 1 DONE');
      }

      if (cdLadder.debug) {
        // cdLadder.debug_log.push('CALC 2:* Complete!');
        // cdLadder.debug_log.push(CDLadderCalculation2);
        console.log(CDLadderCalculation2, 'CALC 2 DONE');
      }

      //Once all ladders' renewal iteration calculations are done,
      // then this calculation is complete. The CD Ladder total balance
      //is the sum of each ladder's final iteration Total.
      if (cdLadder.debug) {
        // cdLadder.debug_log.push('CALC 1:* Total');
        // cdLadder.debug_log.push(cdLadder.runningTotal);
        // cdLadder.debug_log.push('CALC 2:* Total');
        // cdLadder.debug_log.push(cdLadder.runningTotal2);
        // console.log(cdLadder.debug_log, 'CALC 1 + 2 Total');
      }

      //6 Month CD Calculation for comparison

      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2
      })

      if (depositAmount == '' || isNaN(depositAmount)) {
        alert('Please make sure to include a deposit amount');
        return false;
      }else{
        //success means we can remove the button
        $('#Submit').remove();
      }
      $('.chart-container').css('visibility','visible');
      $('.chart-container').show('fast');

      //string replace result text first
      $('.result-title')
        .text(
          $('.result-title')
            .text()
            .replace('{duration}', parseInt($('.input-group select').val()))
        );
      $('.results').show('fast').focus();
      //build dataset with total
      let CDLadderCalculationArr = Object.values(CDLadderCalculation);
      CDLadderCalculationArr.pop();// remove
      let CDLadderCalculation2Arr = Object.values(CDLadderCalculation2);
      let calc1Results = CDLadderCalculationArr.map(a => a.Duration); //make an array of calculated totals
      let calc2Results = CDLadderCalculation2Arr.map(a => a.Duration); //make an array of calculated totals
      let durationResultsLabels = CDLadderCalculationArr.map(a => a.Duration); //make an array of calculated totals

      //set your total vs example
      if(cdLadder.runningTotal2 == 0){
        $('.examp-total').text('The provided values could not be used in an auto renewing CD');
      }else{

        $('.examp-total').text( formatter.format(cdLadder.runningTotal2));
      }

      $('.cd-ladder-calc .input-group').last().append('<a class=\'retry btn btn-primary\' style=\'color:#fff;\' onClick=\'return window.location.href = window.location.href;\'>Retry</a>');


      var ctx = document.getElementById('myChart').getContext('2d');
      Chart.pluginService.register({
        beforeRender: function (chart) {
          if (chart.config.options.showAllTooltips) {
            // create an array of tooltips
            // we can't use the chart tooltip because there is only one tooltip per chart
            chart.pluginTooltips = [];
            chart.config.data.datasets.forEach(function (dataset, i) {
              chart.getDatasetMeta(i).data.forEach(function (sector, j) {
                chart.pluginTooltips.push(new Chart.Tooltip({
                  _chart: chart.chart,
                  _chartInstance: chart,
                  _data: chart.data,
                  _options: chart.options,
                  _active: [sector]
                }, chart));
              });
            });

            // turn off normal tooltips
            chart.options.tooltips.enabled = false;
          }
        },
        afterDraw: function (chart, easing) {
          if (chart.config.options.showAllTooltips) {
            // we don't want the permanent tooltips to animate, so don't do anything till the animation runs atleast once
            if (!chart.allTooltipsOnce) {
              if (easing !== 1)
              // return;
              chart.allTooltipsOnce = true;
            }

            // console.log('action after draw');
            // turn on tooltips
            chart.options.tooltips.enabled = true;
            Chart.helpers.each(chart.pluginTooltips, function (tooltip) {
              tooltip.initialize();
              tooltip.update();
              // we don't actually need this since we are not animating tooltips
              tooltip.pivot();
              tooltip.transition(easing).draw();
            });
            chart.options.tooltips.enabled = false;
          }
        }
      });

      let labels = calc1Results.map((x,i)=>{
        return x + ' Months';
      });

      setTimeout(()=>{
        var myChart = new Chart(ctx, {
            type: 'bar',
            data: {
                labels: labels,
                legend:false,
                datasets: [{
                  label: 'Example CD',
                    results: CDLadderCalculation,
                    data: calc1Results,
                    backgroundColor: [
                        'rgba(0, 124, 189, 1)',
                        'rgba(0, 124, 189, 1)',
                        'rgba(0, 124, 189, 1)',
                        'rgba(0, 124, 189, 1)',
                    ],
                    borderColor: [
                        'rgba(0, 0, 0, 1)',
                        'rgba(0, 0, 0, 1)',
                        'rgba(0, 0, 0, 1)',
                        'rgba(0, 0, 0, 1)',
                    ],
                    borderWidth: 1
                },{
                  label: 'Ladder Result',
                  results: CDLadderCalculation2,
                  data: calc2Results,
                  backgroundColor: [
                    'rgba(0, 124, 189, 0.6)',
                    'rgba(0, 124, 189, 0.6)',
                    'rgba(0, 124, 189, 0.6)',
                    'rgba(0, 124, 189, 0.6)',
                  ],
                  borderColor: [
                    'rgba(0, 0, 0, 1)',
                    'rgba(0, 0, 0, 1)',
                    'rgba(0, 0, 0, 1)',
                    'rgba(0, 0, 0, 1)',
                  ],
                  borderWidth: 1
              }
              ]
            },
            options: {
              showAllTooltips: true,
              legend:false,
              tooltips: {
                mode: 'index',
                intersect: false
              },
              responsive: false,
              maintainAspectRatio: true,
              scales: {
                xAxes: [{
                  stacked: true,
                }],
                yAxes: [{
                    ticks: {
                        beginAtZero: true
                    },
                    stacked: true,
                    scaleLabel:{
                      display: true,
                      labelString: 'Months'
                    }
                }]
              },
              plugins: {
                  p1: true   // disable plugin 'p1' for this instance
              },
              tooltips: {
                // Disable the on-canvas tooltip
                enabled: false,
                callbacks: {
                  label: function(tooltipItem, data) {
                      var label = formatter.format(data.datasets[tooltipItem.datasetIndex].results[tooltipItem.index].Total) || '';
                    // console.log(tooltipItem,'label callback', data.datasets[tooltipItem.datasetIndex].results[tooltipItem.index]);
                      if (label) {
                          label += '<br> Months: ';
                      }
                      label += Math.round(tooltipItem.yLabel * 100) / 100;
                      label += '<br> APY: '+ (data.datasets[tooltipItem.datasetIndex].results[tooltipItem.index].APY * 100)+'%' ;
                      return label;
                    }
                },
                custom: function(tooltipModel) {
                    // Tooltip Element
                    var tooltipEl = document.getElementById('chartjs-tooltip');

                    // Create element on first render
                    if (!tooltipEl) {
                        tooltipEl = document.createElement('div');
                        tooltipEl.id = 'chartjs-tooltip';
                        tooltipEl.innerHTML = '<table></table>';
                        document.body.appendChild(tooltipEl);
                    }

                    // Hide if no tooltip
                    if (tooltipModel.opacity === 0) {
                        tooltipEl.style.opacity = 0;
                        return;
                    }

                    // Set caret Position
                    tooltipEl.classList.remove('above', 'below', 'no-transform');
                    if (tooltipModel.yAlign) {
                        tooltipEl.classList.add(tooltipModel.yAlign);
                    } else {
                        tooltipEl.classList.add('no-transform');
                    }

                    function getBody(bodyItem) {
                        return bodyItem.lines;
                    }

                    // Set Text
                    if (tooltipModel.body) {
                        var titleLines = tooltipModel.title || [];
                        var bodyLines = tooltipModel.body.map(getBody);
                        // console.log(tooltipModel, 'tooltip avail');
                        var innerHtml = '<thead>';

                        titleLines.forEach(function(title) {
                            innerHtml += '<tr><th style="color:#fff">' + title + '</th></tr>';
                        });
                        innerHtml += '</thead><tbody>';

                        bodyLines.forEach(function(body, i) {
                            var colors = tooltipModel.labelColors[i];
                            var style = 'color:#fff; background:rgba( 	0, 134, 60, 1)';
                            style += '; border-color:' + colors.borderColor;
                            style += '; border-width: 2px';
                            var span = '<span style="' + style + '"></span>';
                            innerHtml += '<tr><td style="color:#fff;font-size:16px;font-weight:bold; ">' + span + body + '</td></tr>';
                        });
                        innerHtml += '</tbody>';

                        var tableRoot = tooltipEl.querySelector('table');
                        tableRoot.innerHTML = innerHtml;
                    }

                    // `this` will be the overall tooltip
                    var position = this._chart.canvas.getBoundingClientRect();

                    // Display, position, and set styles for font
                    tooltipEl.style.opacity = 1;
                    tooltipEl.style.display = 'block';
                    tooltipEl.style.background = 'rgba( 	0, 134, 60, 1)';
                    tooltipEl.style.position = 'absolute';
                    tooltipEl.style.left = position.left - 25 + tooltipModel.caretX + 'px';
                    tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
                    tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
                    tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
                    tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;

                    tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + '12px';
                    tooltipEl.style.pointerEvents = 'none';
                }
            }
            }
        });

      },1000);

      //run example at this point
      cdLadder.example();

      setInterval(()=>{
        Chart.instances[0].resize();
      },500)
      // Chart.instances[0].resize();
      //may need this for print stylesheet per
      //https://www.chartjs.org/docs/latest/general/responsive.html?h=print
      // function beforePrintHandler () {
      //     for (var id in Chart.instances) {
      //         Chart.instances[id].resize();
      //     }
      // }
    });
  },
  renew:(calculatedObj, prevTotal, prevMonths)=>{
    let months = 0;
    let depositAmount = parseFloat($('#depositAmount').val());
    let years = parseInt($('.input-group select').val());
    let result= [];

        let CDLadderCalculation2;

    if (prevTotal) {
      console.log(prevTotal, prevMonths, calculatedObj, 'prev');
      result = [calculatedObj];
    }else{

      result = calculatedObj;
    }

    let element = result[0];
    console.log(result, 'result', element.Duration);
    let durationCondition =  (element.Duration + 6 <= years * 12);
      if (durationCondition) {
        // Look up the 1 unique APY and Month values for
        // CD Type = CD Ladder,
        // Initial/Renewal = Renewal,
        // Months = 24,
        let apy = [];

        // Look up the 1 unique APY and Months values for
        for (let x = 0; x < customCDTable.length; x++) {
          const row = customCDTable[x];
          //MIN AND MAX are from previous calculated totals
          let _months = ['6'];
          // where Previous Calculation's Total is in the Minimum/Maximum Deposit range:

          // console.log('prev total', cdLadder.runningTotal);
          if (
            row['CD Type'] == 'cd-6-month' &&
            row['Initial Renewal'] == 'renewal' &&
            _months.includes(row['Months']) && //needs to be within
            cdLadder.runningTotal3 >= 5000 &&//needs to be within
            cdLadder.runningTotal3 <= 250000//needs to be within
            ) {
             //check for dupes
             if (!apy.includes(row['APY'])) {
              apy.push(row['APY']);
            }

          }
        }

        //add calc obj
        let monthCalculated = parseInt(6);
        let IntersetCalculated = (cdLadder.runningTotal3 * apy[0] * (monthCalculated/12));
        if (months === 0) {
          months = 6;
        }
        if (prevMonths) {
          months = prevMonths;
        }
        months = months + 6;// this is static and happens each time
        cdLadder.runningTotal4 = (IntersetCalculated + cdLadder.runningTotal3);
        CDLadderCalculation2 = {
          'APY': apy[0], //there should only be one returned per spec! "Look up the 1 unique APY and Month values"
          'Principle': cdLadder.runningTotal3, //[Previous Calculation's Total]
          'Interest': IntersetCalculated, //Principle * APY * (Months / 12)
          'Total': (IntersetCalculated + cdLadder.runningTotal3),//Principle + Interest
          'Duration': months, // [Previous Calculation's Months] + Months
          'GrandTotal' : cdLadder.runningTotal4 //for later use; calculate the some of totals
        };


        cdLadder.renew(CDLadderCalculation2, cdLadder.runningTotal4, months);

      }else{
        return cdLadder.runningTotal4;
      }


  },
  example:()=>{
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2
    })
    if (cdLadder.debug) {
      cdLadder.debug_log.push('Example calculator cdLadder.example()');
      // console.log(cdLadder.debug_log, 'begining example');
    }
    //query
    let _APY = cdLadder.queryHandler('cd-6-month', 'initial', ['6'], 5000, 250000, customCDTable, '_APY');
    let _Months = cdLadder.queryHandler('cd-6-month', 'initial', ['6'], 5000, 250000, customCDTable, '_Months');
    let months = 6;
    let depositAmount = parseFloat($('#depositAmount').val());
    let years = parseInt($('.input-group select').val());

    // console.log('example', _APY, _Months);
    // console.log('results', _APY, _Months, depositAmount, years)
    //calculations based on spec
    //https://docs.google.com/document/d/1hymRu7PTdcfjfFeBVL-BcqOJMJczQ58WZkRavN6XXYI/edit
    let CDLadderCalculation  = {};
    let CDLadderCalculation2 = {};

      let PrincipleCalculated = (depositAmount);
      let monthCalculated = parseInt(6);
      let apyCalculated = parseFloat(_APY[0]);
      let IntersetCalculated = (PrincipleCalculated * apyCalculated * (monthCalculated/12));

      // console.log((PrincipleCalculated * apyCalculated * (monthCalculated/12)), 'check math *example calc*', monthCalculated, apyCalculated)

      //log a running total as last property for access later

        cdLadder.runningTotal3 = cdLadder.runningTotal3 + (IntersetCalculated + PrincipleCalculated);

      CDLadderCalculation[0] = {
        'APY': _APY[0],
        'Principle': PrincipleCalculated,
        'Interest': IntersetCalculated,
        'Total': (IntersetCalculated + PrincipleCalculated),
        'Duration': 6,
        'GrandTotal' : cdLadder.runningTotal3 //for later use; calculate the some of totals
      };
      //[Previous Calculation's Months] + Months
      //attach to obj for ref
      CDLadderCalculation['months'] = months;

      //for each ladder, perform the initial calc 2:
      // For each ladder,
      // while its Duration + 24 is less than or equal to input value Ladder Years * 12,
      // perform a renewal iteration calculation:

      console.log('next query', monthCalculated + 6, years * 12);

      cdLadder.renew(CDLadderCalculation, false, false);


    // console.log('1st calc', CDLadderCalculation)
    //example CD built
    //build dataset with total
    let CDLadderCalculationArr = Object.values(CDLadderCalculation);
    CDLadderCalculationArr.pop();// remove
    let CDLadderCalculation2Arr = Object.values(CDLadderCalculation2);
    let calc1Results = CDLadderCalculationArr.map(a => a.Total); //make an array of calculated totals
    let calc2Results = CDLadderCalculation2Arr.map(a => a.Total); //make an array of calculated totals
    let durationResultsLabels = CDLadderCalculationArr.map(a => a.Duration); //make an array of calculated totals
    $('.total').text( formatter.format(cdLadder.runningTotal4) );
    // var ctx = document.getElementById('exampleSixMonthCD').getContext('2d');
    // var myChart = new Chart(ctx, {
    //     type: 'bar',
    //     data: {
    //         labels: durationResultsLabels,
    //         datasets: [{
    //             label: 'Potential balance after '+years+' years',
    //             data: calc1Results,
    //             backgroundColor: [
    //                 'rgba(255, 99, 132, 0.2)',
    //                 'rgba(54, 162, 235, 0.2)',
    //                 'rgba(255, 206, 86, 0.2)',
    //                 'rgba(75, 192, 192, 0.2)',
    //                 'rgba(153, 102, 255, 0.2)',
    //                 'rgba(255, 159, 64, 0.2)'
    //             ],
    //             borderColor: [
    //                 'rgba(255, 99, 132, 1)',
    //                 'rgba(54, 162, 235, 1)',
    //                 'rgba(255, 206, 86, 1)',
    //                 'rgba(75, 192, 192, 1)'
    //             ],
    //             borderWidth: 1
    //         },{
    //           data: calc2Results,
    //           backgroundColor: [
    //               'rgba(255, 99, 132, 0.4)',
    //               'rgba(54, 162, 235, 0.4)',
    //               'rgba(255, 206, 86, 0.4)',
    //               'rgba(75, 192, 192, 0.4)'
    //           ],
    //           borderColor: [
    //               'rgba(255, 99, 132, 1)',
    //               'rgba(54, 162, 235, 1)',
    //               'rgba(255, 206, 86, 1)',
    //               'rgba(75, 192, 192, 1)',
    //               'rgba(153, 102, 255, 1)',
    //               'rgba(255, 159, 64, 1)'
    //           ],
    //           borderWidth: 1
    //       }
    //       ]
    //     },
    //     options: {
    //         scales: {
    //             yAxes: [{
    //                 ticks: {
    //                     beginAtZero: false
    //                 }
    //             }]
    //         }
    //     }
    // });
  }
}

if ($('.cd-ladder-calc').length) {
  // Important : Disable old CD ladder initialization
  // Remove this old CD Ladder code once rewrite in `cd-ladder.js` is complete - SK 20200619
  // cdLadder.init();
}

