function scrollToContainer(hash) {
  const anchorLinkCta = document.querySelector('.anchor-link-cta');
  const yOffset = document.querySelector(hash).offsetTop;

  if (anchorLinkCta) {
    const anchorLinkCtaHeight = anchorLinkCta.clientHeight;
    setTimeout(() => {
      window.scrollTo({
        top: (yOffset - anchorLinkCtaHeight),
        left: 0,
        behavior: 'smooth' });
    }, 50);
  } else {
    setTimeout(() => {
      window.scrollTo({
        top: yOffset,
        left: 0,
        behavior: 'smooth' });
    }, 50);
  }
}

export { scrollToContainer };
